
.glx-header{
  z-index: 1000;
  .glx-header--content-left{
    h1{
      position: absolute;
      left: 5px;
      font-family: 'IBM Plex Mono', sans-serif;
      font-weight: bold;
      font-size: 26pt;
    }
    svg{
      position: absolute;
      left: 190px;
      top: 4px;
    }
    @extend .max-frame;
    width: 200px;
  }
  .glx-header--content-right{
    height: 100%;
    position: absolute;
    width: 500px;
    top:0;
    right: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    a{
      padding: 8px;
      background: transparent;
      border: none;
      font-family: 'IBM Plex Mono', sans-serif;
      font-size: 20pt;
      color: $glx-bg-light;
      cursor: pointer;
      text-decoration: none;
    }
    a:hover{
      text-decoration: underline;
    }
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-size;
  border-bottom: 1px;
  border-bottom-style: solid;
  border-color: black;
  color: $glx-bg-light;
  background-color: $glx-bg-dark;
}
.glx-content--spacer-header{
  width: 100%;
  height: $header-size;
}
