.fade-element {
  opacity: 0.01;
  transition: opacity 600ms ease-out, transform 600ms ease-out;
  will-change: opacity, visibility;

}

.is-visible {
  opacity: 1;
  transform: none;
}
