@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: normal;
  src: url('./font/IBMPlexSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: bold;
  src: url('./font/IBMPlexSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: normal;
  src: url('./font/IBMPlexSans-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: normal;
  src: url('./font/IBMPlexMono-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: bold;
  src: url('./font/IBMPlexMono-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: normal;
  src: url('./font/IBMPlexMono-Italic.ttf') format('truetype');
}
