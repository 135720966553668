@import "Color";
@import "@grandlinex/react-components/style/style";
@import "Constants";
@import "Components";
@import "Font";
@import "Header";
@import "Preiview";
@import "ContentRow";
@import "FeatureBlock";
@import "annimation";
@import "fading";



.max-frame{
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

html{
  @extend .max-frame;
  color: $glx-dark-accent01;
  background-color: white;
 }


#root{
  @extend .max-frame;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
 }
.module{
  @extend .max-frame;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
  --glx-main-text: #505B6B;
}

#root::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

body {
  @extend .max-frame;
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.glx-card-footer{
  bottom: 8px;
  position: absolute;
  width: 100%;
  left: 0;
}

.glx-card-footer--space{
  width: 100%;
  height: 70px;
}

.glx-mono{
  font-family: 'IBM Plex Mono', sans-serif;
}

a,a:active{
  color: $glx-bg-light;
}


@media (max-width: 767px) {
  .glx--hide-on-mobile{
    display: none;
  }
}

@media (min-width: 767px) {
  .glx--only-on-desktop{
    display: none;
  }
}

.app{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}



.headline{
  padding-top: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: $glx-bg-dark;
  height: $fron-size;
}

.content-center{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  // outline: 1px solid red;
}
.content-center-rel{
  @extend .content-center;
    position: relative;
}

.content-start{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // outline: 1px solid red;
}


