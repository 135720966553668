@import "Constants";
@import "Color";




.glx-content--space{
  width: 100%;
  height: 90px;
}
.glx-footer-rel{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.glx-footer{
  background-color: $glx-bg-light;
  height: 210px;
  font-family: 'IBM Plex Mono', sans-serif;
  a,a:active{
    color: $glx-bg-dark;
  }
  div{
    float: left;
    padding: 8px;
  }
  ul {
    list-style-type: none;
  }
  .glx-footer-icons{
    a{
      padding: 8px;
      background: transparent;
      border: none;
      font-family: 'IBM Plex Mono', sans-serif;
      font-size: 20pt;
      color: $glx-bg-dark;
      cursor: pointer;
      text-decoration: none;
    }
    a:hover{
      text-decoration: underline;
    }
  }
}

.glx-repo--block,.glx-feature--block{
  flex-direction: row;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap:  wrap ;
}

.glx-repo--block-item{
  position: relative;
  background-color: $glx-bg-dark;
  color: $glx-bg-light;
  border-radius: 14px;
  min-width: 335px;
  max-width: 440px;
  margin: 8px;
  padding: 12px;
  h3{
    text-decoration: underline;
    text-align: center;
    font-size: 24pt;
  }
  pre{
    text-align: center;
    width: 100%;
  }
  .glx-button-grid{
    gap: 48px;
    a{
      color: $glx-bg-light;
      font-size: 20pt;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
  .glx-repo--block-icon{
    position: absolute;
    top:12px;
    left: 12px;
   }
  .glx-repo--block-new{
    position: absolute;
    top:12px;
    right: 12px;
    font-weight: 800;
   }
}
.glx-description{
  font-weight: bold;
}

pre code{
  background-color: $glx-dark-accent05;
  padding: 4px;
  width: 100%;
}

.glx-sandbox{
  width: 90%;
}

.glx-repo--app-verison{
  cursor: pointer;
}
